@font-face {
  font-family: "SourceSansPro Italic";
  font-display: swap;
  src: url("../fonts/source-sans-pro/SourceSansPro-Italic.ttf")
    format("truetype");
}
@font-face {
  font-family: "SourceSansPro Light";
  font-display: swap;
  src: url("../fonts/source-sans-pro/SourceSansPro-Light.ttf")
    format("truetype");
}
@font-face {
  font-family: "SourceSansPro Regular";
  font-display: swap;
  src: url("../fonts/source-sans-pro/SourceSansPro-Regular.ttf")
    format("truetype");
}
@font-face {
  font-family: "SourceSansPro SemiBold";
  font-display: swap;
  src: url("../fonts/source-sans-pro/SourceSansPro-SemiBold.ttf")
    format("truetype");
}
@font-face {
  font-family: "SourceSansPro Bold";
  font-display: swap;
  src: url("../fonts/source-sans-pro/SourceSansPro-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik Light";
  font-display: swap;
  src: url("../fonts/rubik/Rubik-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik Regular";
  font-display: swap;
  src: url("../fonts/rubik/Rubik-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik SemiBold";
  font-display: swap;
  src: url("../fonts/rubik/Rubik-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Rubik Bold";
  font-display: swap;
  src: url("../fonts/rubik/Rubik-Bold.ttf") format("truetype");
}

$sourceSasnsProItalic: "SourceSansPro Italic", sans-serif;
$sourceSasnsProLight: "SourceSansPro Light", sans-serif;
$sourceSasnsProRegular: "SourceSansPro Regular", sans-serif;
$sourceSasnsProSemiBold: "SourceSansPro SemiBold", sans-serif;
$sourceSasnsProBold: "SourceSansPro Bold", sans-serif;
$rubikLight: "Rubik Light", sans-serif;
$rubikRegular: "Rubik Regular", sans-serif;
$rubikSemiBold: "Rubik SemiBold", sans-serif;
$rubikBold: "Rubik Bold", sans-serif;

$darkColor: #42424e;
$redColor: #ff4e77;
$greenColor: #08bc62;
$darkGreenColor: #3bc870;
$primaryOrangeColor: #fe5000;
$hoverColor: #f5f7f9;

$smallFontSize: 15px;
$mediumFontSize: 18px;
$largeFontSize: 30px;
$extraLargeFontSize: 40px;
$ultraLargeFontSize: 50px;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  outline: none;
  text-decoration: none;
  font-family: $sourceSasnsProRegular;
}

.finance-table-head {
  font-size: 12px;
}
.finance-table-body {  
  font-size: 12px;
}
.finance-table-body:focus {  
  font-size: 13px;
  border: 1px solid #ffffff;
  border-radius: 10px;
}
.finance-table {  
  border: 1px solid #e1e8f0; 
  border-radius: 4px;
}
.finance-table-input {
  border: none;
  background-color: transparent;
  text-align: right;
  width: 100%;
}
.finance-table-description-input {
  border: none;
  width: 100%;
  background-color: transparent;
}
.cursor-default {
  cursor: default !important;
}

.leaflet-tooltip {
  padding: 2px !important;
  font-weight: 600;
  font-family: $sourceSasnsProSemiBold;
  font-size: 15px;
  color: #313a58;
  line-height: 1;
}

.link {
  color: #0189ff;
  font-size: 14px;
  font-family: $rubikRegular;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

p.error-text {
  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  max-width: 100%;
  line-height: 0.8;
}

ul.audio-records {
  width: 100%;
  margin-top: 10px;
  li {
    background: #ffffff;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: 100%;
    padding: 8px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    span.control {
      background: #fe5000;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      cursor: pointer;
      img {
        max-width: calc(100% - 14px);
        max-height: calc(100% - 14px);
        object-fit: contain;
        pointer-events: none;
      }
    }
    time {
      color: #fe5000;
      font-size: 14px;
      font-family: $sourceSasnsProRegular;
      margin-left: auto;
    }
    span.remove {
      display: block;
      width: 25px;
      height: 25px;
      cursor: pointer;
      border-radius: 4px;
      background-image: url("../images/schedule/icons/remove-gray.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px auto;
      margin-left: 5px;
      &:hover {
        background-color: $hoverColor;
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  opacity: 0;
  pointer-events: none;
  &.add-edit-task-overlay,
  &.add-edit-user-overlay,
  &.sums-list-overlay,
  &.audio-records-overlay,
  &.select-category-and-service-overlay,
  &.select-worker-and-date-overlay,
  &.add-hour-overlay,
  &.additional-workers-overlay {
    .modal,
    > form,
    .form-holder {
      background: #ffffff;
      box-shadow: 0px 0px 25px rgba(120, 132, 176, 0.17);
      border-radius: 4px;
      padding: 20px;
      width: 370px;
      position: relative;
      > b {
        color: #fe5000;
        font-size: 20px;
        font-family: $sourceSasnsProSemiBold;
        display: block;
        margin-bottom: 30px;
      }
      span.close {
        width: 20px;
        height: 20px;
        background-image: url("../images/users/icons/close.svg");
        background-size: 14px auto;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        display: block;
        position: absolute;
        top: 23px;
        right: 20px;
      }
    }
    &.add-edit-user-overlay {
      span.change-password {
        color: $darkColor;
        font-size: $smallFontSize;
        cursor: pointer;
        text-decoration: underline;
        display: flex;
        align-items: center;
        width: fit-content;
        img {
          margin-right: 5px;
        }
      }
      .fields {
        .row {
          margin-bottom: 20px;
          .col {
            margin-bottom: 0;
          }
        }
        .col {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          position: relative;
          label {
            color: #aeb7c9;
            font-size: 14px;
            font-family: $sourceSasnsProSemiBold;
            padding-bottom: 2px;
          }
          &.small {
            max-width: 100px;
          }
        }
        .row {
          width: 100%;
          .col {
            width: calc(50% - 5px);
          }
        }
      }
    }
  }
  &.remove-overlay,
  &.question-overlay {
    z-index: 993;
    .modal {
      width: 430px;
      padding: 20px;
      background: #fcfdff;
      box-shadow: 0px 0px 25px rgba(120, 132, 176, 0.17);
      border-radius: 4px;
      transition: 0.2s;
      overflow: hidden;
      .body {
        > img.main {
          display: block;
          margin: 0 auto;
          margin-bottom: 10px;
        }
        b {
          color: $darkColor;
          font-size: $largeFontSize;
          font-family: $sourceSasnsProSemiBold;
          display: block;
          text-align: center;
          margin-bottom: 10px;
          font-weight: normal;
          line-height: 1;
        }
        p {
          text-align: center;
          color: $sourceSasnsProRegular;
          font-size: $smallFontSize;
          line-height: 1.4;
          margin-bottom: 20px;
        }
      }
      .buttons {
        button.btn-primary {
          background-color: $redColor;
        }
      }
    }
  }
  &.question-overlay {
    .modal {
      .buttons {
        button {
          &.btn-primary {
            background-color: #f64e00;
          }
        }
      }
    }
  }
  &.sums-list-overlay {
    .no-results {
      img {
        height: 100px;
      }
      b {
        font-size: 20px;
      }
    }
    ul {
      width: 100%;
      max-height: 155px;
      overflow: auto;
      li.row {
        width: 100%;
        align-items: center;
        padding: 10px;
        background: #f5fbff;
        border: 1px solid #e1e8f0;
        border-radius: 4px;
        h6 {
          color: #42424e;
          font-size: 18px;
          font-family: "SourceSansPro SemiBold", sans-serif;
          padding-right: 10px;
        }
        span.info {
          cursor: default;
          margin-left: auto;
        }
        span.remove {
          cursor: pointer;
          margin-left: 10px;
        }
        span.remove,
        span.info {
          height: 18px;
          img {
            height: 19px;
            object-fit: scale-down;
            object-position: center;
          }
        }
      }
    }
  }
  &.audio-records-overlay {
    z-index: 991;
    .modal {
      .audio-records {
        max-height: 150px;
        overflow: auto;
        padding: 5px;
        li {
          box-shadow: none;
          border: 1px solid #eee;
        }
      }
    }
  }
  &.add-edit-task-overlay {
    overflow: auto;
    padding: 25px 0;
    justify-content: initial;
    align-items: initial;
    .form-holder {
      width: 770px;
      min-height: 670px;
      margin: auto;
      display: flex;
      flex-direction: column;
      .row {
        position: relative;
        .download-protocol {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 40px;
          border: none;
          padding: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            max-height: 100%;
            margin-right: 5px;
          }
        }
      }
      .tab {
        padding: 25px 20px;
        border-top: 1px solid #e2eaef;
        width: calc(100% + 40px);
        margin-left: -20px;
        &.task-data {
          padding-top: 8px;
          padding-bottom: 15px;
          .select-category {
            border-radius: 6px;
            padding: 10px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            margin-bottom: 10px;
            margin-left: -10px;
            margin-right: -10px;
            width: calc(100% + 20px);
            .left {
              display: flex;
              align-items: center;
              span {
                width: 39px;
                height: 39px;
                display: flex;
                flex-shrink: 0;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                padding: 8px;
                margin-right: 10px;
                img {
                  max-width: 100%;
                  max-height: 100%;
                  object-fit: scale-down;
                }
              }
              b {
                color: #313a58;
                font-family: $sourceSasnsProSemiBold;
                font-size: 24px;
              }
            }
            &:hover {
              background-color: $hoverColor;
            }
          }
          .worker-data {
            border-top: 1px solid #e2eaef;
            border-bottom: 1px solid #e2eaef;
            padding: 5px 0;
            // justify-content: space-between;
            flex-direction: column;
            width: calc(100% + 40px);
            margin-left: -20px;
            margin-right: -20px;
            .date-and-hour-holder {
              display: flex;
              // border-left: 1px solid #e2eaef;
              padding-left: 10px;
              padding-right: 10px;
              .start,
              .end {
                display: flex;
                align-items: center;
                padding: 8px;
                padding-right: 30px;
                border-radius: 6px;
                min-height: 59px;
                position: relative;
                min-width: 210px;
                img {
                  flex-shrink: 0;
                  margin-right: 10px;
                }
                .right {
                  p {
                    color: #aeb7c9;
                    font-size: 14px;
                    line-height: 1;
                  }
                  b {
                    color: #313a58;
                    font-size: 20px;
                    display: block;
                    font-family: $sourceSasnsProSemiBold;
                    input {
                      font-size: inherit;
                      font-family: inherit;
                      border: none;
                      position: relative;
                      z-index: 9;
                      height: inherit;
                      padding: 0 2px;
                      margin-top: 2px;
                      background-color: transparent;
                      &::-webkit-calendar-picker-indicator {
                        background: none;
                        display: none;
                      }
                      &:hover {
                        background-color: #fff;
                      }
                    }
                  }
                }
                span.remove {
                  width: 20px;
                  height: 20px;
                  background-image: url("../images/schedule/icons/close.svg");
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: 15px;
                  cursor: pointer;
                  z-index: 3;
                  position: absolute;
                  right: 8px;
                  top: 50%;
                  transform: translateY(-50%);
                  &:hover {
                    background-color: #f1f1f1;
                  }
                }
                input[type="date"] {
                  cursor: pointer;
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                  padding: 0;
                  z-index: 2;
                  &::-webkit-calendar-picker-indicator {
                    background: transparent;
                    bottom: 0;
                    color: transparent;
                    cursor: pointer;
                    height: auto;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: auto;
                  }
                }
                &:hover {
                  background-color: $hoverColor;
                }
              }
              .start {
                margin-right: 10px;
              }
              div[aria-label] {
                flex: 1;
              }
            }
            .right-col {
              padding-left: 10px;
              padding-right: 10px;
              border-bottom: 1px solid #e2eaef;
              padding-bottom: 5px;
              margin-bottom: 5px;
              width: 100%;
              position: relative;
              .worker-holder {
                display: flex;
                align-items: center;
                padding: 8px;
                padding-right: 55px;
                border-radius: 6px;
                min-height: 55px;
                position: relative;
                min-width: 210px;
                width: 100%;
                cursor: pointer;
                img {
                  flex-shrink: 0;
                  margin-right: 10px;
                }
                .right {
                  p {
                    color: #aeb7c9;
                    font-size: 14px;
                    line-height: 1;
                  }
                  b {
                    color: #313a58;
                    font-size: 20px;
                    display: block;
                    font-family: $sourceSasnsProSemiBold;
                  }
                }
                &:hover {
                  background-color: $hoverColor;
                }
              }
              button.btn-add {
                position: absolute;
                right: 25px;
                top: 50%;
                transform: translateY(-50%);
                background-position: center;
                width: 30px;
                height: 30px;
                min-width: initial;
                padding: 0;
                background-image: url("../images/schedule/icons/worker-white.svg");
              }
            }
          }
          .description-fields-holder,
          .uploaded-files-holder,
          .extra-description-holder,
          .payment-holder {
            h6 {
              color: #aeb7c9;
              font-size: 14px;
              font-family: $sourceSasnsProRegular;
              display: flex;
              align-items: center;
              font-weight: normal;
              margin-bottom: 15px;
              img {
                margin-right: 5px;
              }
            }
            p:not(.error-text) {
              font-size: 12px;
              color: #aeb7c9;
              font-family: $sourceSasnsProItalic;
              font-style: italic;
            }
          }
          .extra-data {
            padding: 10px 0;
            .description-fields-holder {
              width: calc(100% - 221px);
              padding-right: 121px;
              ul {
                width: 100%;
                max-height: 170px;
                overflow: auto;
                li {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  margin-bottom: 10px;
                  label {
                    color: #6c7287;
                    font-size: 14px;
                    font-family: $rubikRegular;
                    margin-bottom: 2px;
                  }
                  input {
                    border: 1px solid #dde6ed;
                    border-radius: 4px;
                    height: 30px;
                    padding: 0 5px;
                    width: 100%;
                    &::placeholder {
                      color: #aeb7c9;
                      font-size: 14px;
                      font-family: $rubikRegular;
                    }
                  }
                  &:last-of-type {
                    margin-bottom: 0;
                  }
                }
              }
              p {
                span.link {
                  font-size: inherit;
                  font-family: inherit;
                  text-decoration: underline;
                }
              }
            }
            .uploaded-files-holder {
              width: 221px;
              flex-shrink: 0;
              .select {
                margin-bottom: 10px;
                button {
                  background-color: #fe5000;
                  border-radius: 50%;
                  width: 41px;
                  height: 41px;
                  border: none;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 10px;
                  flex-shrink: 0;
                  position: relative;
                  overflow: hidden;
                  input {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    cursor: pointer;
                  }
                  img {
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: scale-down;
                    object-position: center;
                  }
                  &:hover {
                    background-color: #f34f03;
                  }
                }
              }
              ul.images {
                display: flex;
                justify-content: space-between;
                li {
                  border: 1px solid #eee;
                  border-radius: 4px;
                  overflow: hidden;
                  cursor: pointer;
                  height: 65px;
                  width: calc(50% - 5px);
                  background-color: $hoverColor;
                  position: relative;
                  span.remove {
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    background-color: #fff;
                    background-image: url("../images/schedule/icons/remove-gray.svg");
                    background-size: 14px auto;
                    background-repeat: no-repeat;
                    background-position: center;
                    cursor: pointer;
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    border: 1px solid #eee;
                  }
                  .more {
                    color: #fff;
                    font-size: 30px;
                    font-family: $sourceSasnsProSemiBold;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.4);
                    pointer-events: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                  img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    object-fit: cover;
                  }
                }
              }
              span.link {
                display: block;
                width: fit-content;
                margin: 0 auto;
                margin-top: 10px;
                font-family: $sourceSasnsProRegular;
                font-size: 14px;
              }
            }
          }
          .extra-description-holder {
            // padding-top: 15px;
            position: relative;
            width: calc(100% - 221px);
            padding-right: 50px;
            textarea {
              width: 100%;
              height: 77px;
            }
          }
          .payment-holder {
            padding-top: 10px;
            .col {
              ul {
                display: flex;
                flex-wrap: wrap;
                li {
                  width: calc(100% / 3);
                  padding-right: 10px;
                  margin-bottom: 10px;
                  .MuiFormControlLabel-root {
                    margin-right: 0;
                  }
                  span.MuiButtonBase-root {
                    padding: 5px;
                  }
                  span.MuiTypography-root {
                    color: #606a82;
                    font-size: 14px;
                    line-height: 1;
                  }
                }
              }
              &:last-of-type {
                max-width: 150px;
              }
            }
          }
          hr {
            margin-left: -20px;
            margin-right: -20px;
            width: calc(100% + 40px);
          }
        }
        &.client-data {
          height: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          > .row {
            flex: 1;
            .col {
              b {
                color: #313a58;
                font-size: 20px;
                font-family: "SourceSansPro SemiBold", sans-serif;
                display: block;
                margin-bottom: 10px;
              }
            }
            .fields-col {
              .col {
                position: relative;
                label {
                  color: #aeb7c9;
                  font-size: 14px;
                  font-family: "SourceSansPro Regular", sans-serif;
                  font-weight: normal;
                  padding-bottom: 5px;
                }
                .holder {
                  position: relative;
                  input {
                    width: 100%;
                    padding-right: 35px;
                  }
                  form {
                    position: relative;
                    span.remove {
                      width: 21px;
                      height: 21px;
                      background-image: url("../images/schedule/icons/close.svg");
                      background-position: center;
                      background-repeat: no-repeat;
                      background-size: 15px;
                      cursor: pointer;
                      z-index: 3;
                      position: absolute;
                      right: 8px;
                      top: 50%;
                      transform: translateY(-50%);
                      &:hover {
                        background-color: #f1f1f1;
                      }
                    }
                  }
                  .dropdown {
                    position: absolute;
                    left: 0;
                    top: 100%;
                    width: 100%;
                    background-color: #fff;
                    z-index: 2;
                    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
                    ul.addresses,
                    ul.settlements {
                      max-height: 100px;
                      overflow: auto;
                      li {
                        padding: 10px;
                        border-bottom: 1px solid #efefef;
                        cursor: pointer;
                        font-size: 14px;
                        color: #313a58;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        span {
                          width: 25px;
                          height: 25px;
                          background: #ffffff;
                          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          padding: 5px;
                          margin-left: 5px;
                          cursor: pointer;
                          border-radius: 50%;
                          img {
                            object-fit: scale-down;
                            width: 100%;
                            height: 100%;
                            pointer-events: none;
                          }
                        }
                        &:last-of-type {
                          border-bottom: none;
                        }
                        &.active,
                        &:hover {
                          background-color: #f5f7f9;
                        }
                      }
                    }
                    ul.add-new-address {
                      li {
                        display: flex;
                        justify-content: center;
                        padding: 10px 0;
                        border-top: 1px solid #eee;
                      }
                    }
                  }
                }
              }
              p:not(.error-text) {
                color: #aeb7c9;
                font-size: 12px;
                font-family: $sourceSasnsProItalic;
                font-style: italic;
                margin-top: 15px;
                span {
                  color: #fe5000;
                  padding-right: 2px;
                }
              }
            }
            .list-col {
              padding-left: 10px;
              margin-left: 10px;
              border-left: 1px solid #e2eaef;
              input {
                margin-bottom: 10px;
              }
              ul {
                height: 325px;
                overflow: auto;
                li {
                  padding: 15px 20px;
                  border-bottom: 1px solid #e2eaef;
                  color: #313a58;
                  font-size: 14px;
                  font-family: "SourceSansPro Regular", sans-serif;
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  span {
                    color: #aeb7c9;
                  }
                  &:hover,
                  &.active {
                    background-color: $hoverColor;
                  }
                }
              }
            }
          }
        }
        &.transactions {
          h6 {
            color: #aeb7c9;
            font-size: 14px;
            font-family: $sourceSasnsProRegular;
            display: flex;
            align-items: center;
            font-weight: normal;
            img {
              margin-right: 5px;
            }
          }
          button.add {
            background: #313a58;
            border-radius: 4px;
            height: 40px;
            width: 160px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            border: none;
            font-size: 14px;
            font-family: $sourceSasnsProRegular;
            cursor: pointer;
            margin-left: auto;
            img {
              margin-right: 5px;
            }
            &:hover {
              background: #29304a;
            }
          }
          > .row {
            > .col:first-of-type {
              border-right: 1px solid #e2eaef;
              padding-right: 15px;
            }
            > .col:last-of-type {
              margin-right: 0;
            }
            .col {
              margin-right: 15px;
              ul.list {
                margin-top: 20px;
                max-height: 400px;
                overflow: auto;
                li {
                  background: #f2f2f7;
                  border: 1px solid #cdcfd6;
                  border-radius: 4px;
                  margin-bottom: 10px;
                  padding: 15px;
                  .row {
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    b {
                      color: #313a58;
                      font-size: 20px;
                      font-family: $sourceSasnsProSemiBold;
                    }
                    span.remove {
                      display: block;
                      width: 25px;
                      height: 25px;
                      cursor: pointer;
                      border-radius: 50%;
                      background-image: url("../images/schedule/icons/remove-gray.svg");
                      background-repeat: no-repeat;
                      background-position: center;
                      background-size: 16px auto;
                      margin-left: 5px;
                      background-color: #fff;
                      &:hover {
                        background-color: $hoverColor;
                      }
                    }
                  }
                  p {
                    color: #313a58;
                    font-size: 14px;
                    font-family: $rubikRegular;
                    line-height: 1.4;
                  }
                  i {
                    color: #a0a6ba;
                    font-size: 11px;
                    font-family: $sourceSasnsProItalic;
                  }
                  &:last-of-type {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
        &.comments {
          form.reply-fields {
            textarea {
              width: 100%;
              height: 70px;
            }
            .buttons {
              border: none;
              margin: 0;
              padding: 10px 0;
            }
          }

          ul.list {
            max-height: 400px;
            overflow: auto;
            li {
              margin-bottom: 15px;
              .top {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                img {
                  width: 20px;
                  margin-right: 5px;
                }
                b {
                  color: #313a58;
                  font-size: 20px;
                  font-family: $sourceSasnsProSemiBold;
                  margin-right: 10px;
                }
                time {
                  color: #b8c0d0;
                  font-size: 12px;
                  font-family: $sourceSasnsProSemiBold;
                  margin-top: auto;
                  margin-bottom: 3px;
                  margin-right: 10px;
                }
                button {
                  margin-left: auto;
                  flex-shrink: 0;
                  background: #ffffff;
                  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
                  border-radius: 50%;
                  cursor: pointer;
                  width: 24px;
                  height: 24px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: none;
                  &:hover {
                    background-color: $hoverColor;
                  }
                }
              }
              p {
                color: #313a58;
                line-height: 1.4;
                font-size: 14px;
                font-family: $rubikRegular;
              }
              .replies-holder {
                padding-top: 15px;
                .reply {
                  margin-bottom: 15px;
                  button.btn-reply {
                    border: none;
                    background-color: transparent;
                    color: #313a58;
                    font-size: 14px;
                    font-family: $sourceSasnsProSemiBold;
                    cursor: pointer;
                    padding: 5px;
                    border-radius: 4px;
                    margin-left: -5px;
                    display: flex;
                    align-items: center;
                    img {
                      margin-right: 5px;
                    }
                    &:hover {
                      background-color: $hoverColor;
                    }
                  }
                  form.reply-fields {
                    position: relative;
                    padding-left: 25px;
                    &::before {
                      content: "";
                      width: 15px;
                      height: 17px;
                      background-image: url("../images/schedule/icons/enter.svg");
                      background-size: contain;
                      background-repeat: no-repeat;
                      background-position: center;
                      position: absolute;
                      left: 5px;
                      top: 10px;
                    }
                  }
                }
                ul.replies {
                  padding-left: 25px;
                }
              }
            }
          }
          .comment-field {
            margin-top: 25px;
            .buttons {
              .status {
                margin-right: auto;
                display: flex;
                align-items: center;
                padding: 8px;
                border-radius: 6px;
                min-height: 40px;
                position: relative;
                cursor: pointer;
                color: #313a58;
                font-size: 14px;
                font-family: "SourceSansPro SemiBold", sans-serif;
                border: 1px solid #dde6ed;
                img {
                  width: 20px;
                  margin-right: 5px;
                }
                &:hover {
                  background-color: $hoverColor;
                }
              }
            }
          }
        }
      }
    }
    .buttons {
      margin-top: auto;
      span.remove {
        margin-right: auto;
        color: #ff4e77;
        font-size: 14px;
        font-family: $sourceSasnsProSemiBold;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: fit-content;
        padding: 5px;
        border-radius: 4px;
        margin-left: -5px;
        img {
          margin-right: 5px;
        }
        &:hover {
          background-color: $hoverColor;
        }
      }
    }
  }
  &.gallery-overlay {
    span.close-gallery {
      display: block;
      width: 40px;
      height: 40px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 40px 40px;
      background-image: url("../images/app/icons/close.svg");
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 15px;
    }
    span.prev,
    span.next,
    span.remove {
      display: block;
      width: 40px;
      height: 40px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 40px 40px;
      position: absolute;
      top: calc(50% - 40px / 2);
      cursor: pointer;
      background-color: rgb(255, 255, 255);
      background-size: 15px auto;
      border-radius: 4px;
      &:hover {
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
    span.prev {
      background-image: url("../images/app/icons/prev-arrow.svg");
      left: 15px;
    }

    span.next {
      background-image: url("../images/app/icons/next-arrow.svg");
      right: 15px;
    }
    .bg {
      height: calc(100vh - 80px);
      width: calc(100vw - 150px);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #000;
      padding: 0;
      position: relative;
      img.main {
        max-height: 100%;
        max-width: 100%;
        display: block;
        margin: auto;
        pointer-events: none;
      }
      span.remove {
        bottom: 0;
        right: 0;
        top: initial;
        background-image: url("../images/schedule/icons/remove-gray.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px auto;
      }
    }
  }
  &.select-category-and-service-overlay {
    z-index: 991;
    .modal {
      width: 600px;
      ul.categories {
        width: calc(100% + 10px);
        margin-right: -10px;
        flex-wrap: wrap;
        li {
          width: calc(100% / 2 - 5px);
          margin-bottom: 5px;
          margin-right: 5px;
          display: flex;
          align-items: center;
          padding: 15px;
          border-radius: 4px;
          cursor: pointer;
          span {
            width: 70px;
            height: 70px;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            padding: 10px;
            margin-right: 10px;
            img {
              max-width: 100%;
              max-height: 100%;
              object-fit: scale-down;
            }
          }
          .right {
            b {
              color: #313a58;
              font-size: 20px;
              font-family: $sourceSasnsProSemiBold;
            }
          }
        }
      }
      ul.subcategories {
        max-height: 300px;
        overflow: auto;
        margin-left: -20px;
        margin-right: -20px;
        width: calc(100% + 40px);
        li {
          padding: 15px 20px;
          border-bottom: 1px solid #e2eaef;
          color: #313a58;
          font-size: 14px;
          font-family: $sourceSasnsProRegular;
          cursor: pointer;
          &:last-of-type {
            border: none;
          }
          &:hover,
          &.active {
            background-color: $hoverColor;
          }
          &.active {
            cursor: default;
          }
        }
      }
    }
  }
  &.select-worker-and-date-overlay {
    z-index: 991;
    .modal {
      // width: 1100px;
      min-height: 605px;
      width: 100%;
      height: 100%;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      .periods {
        .day {
          .indicator {
            width: 150px;
          }
          .right {
            .top {
              .change-filter {
                display: none;
              }
            }
            ul.days {
              li {
                margin: 0 1px;
              }
            }
          }
        }
      }
      ul.weekly-tasks-list {
        // max-height: 300px;
        overflow: auto;
        > li {
          height: fit-content;
          .worker {
            height: 65px;
            border-right: 2px solid #e2eaef;
            flex-shrink: 0;
            border-bottom: 5px solid #e2eaef;
            b {
              font-family: $sourceSasnsProSemiBold;
              font-size: 16px;
              position: relative;
              width: fit-content;
              span {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                font-size: 14px;
                color: #fff;
                background: #313a58;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: calc(100% - 1px);
                top: -9px;
              }
            }
          }
          ul.tasks {
            padding: 0;
            height: initial;
            background-color: #f5f7f9;
            li.day {
              height: 65px;
              border-bottom: 2px solid #e2eaef;
              position: relative;
              .busy {
                background: #ff4e77;
                padding: 5px;
                height: 100%;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                cursor: default;
                z-index: 2;
                pointer-events: none;
                .top {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 5px;
                  span {
                    font-size: 12px;
                    color: #fff;
                    font-family: $rubikSemiBold;
                  }
                  img {
                    width: 15px;
                    height: 15px;
                    object-fit: scale-down;
                  }
                }
                p {
                  font-size: 12px;
                  font-family: $sourceSasnsProRegular;
                  line-height: 1.2;
                  color: #fff;
                }
              }
              button.add {
                background: #dbf4df;
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                margin: -2px;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #3bc870;
                font-size: 14px;
                font-family: $sourceSasnsProRegular;
                cursor: pointer;
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
                img {
                  margin-right: 5px;
                }
              }
              &:hover {
                button.add {
                  opacity: 1;
                }
              }
            }
          }
          &:last-of-type {
            .worker {
              border-right: none;
            }
          }
          &.small {
            width: 100px;
            max-width: 100px;
            position: sticky;
            left: 0;
            z-index: 4;
            ul.small {
              background-color: #f5f7f9;
              li {
                color: #aeb7c9;
                font-size: 16px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 2px solid #e2eaef;
                border-right: 2px solid #e2eaef;
                height: 65px;
              }
            }
          }
        }
      }
      .buttons {
        margin-top: auto;
      }
    }
  }
  &.add-hour-overlay {
    z-index: 992;
    .fields {
      .row {
        .col {
          label {
            color: #aeb7c9;
            font-size: 14px;
            font-family: $sourceSasnsProSemiBold;
            padding-bottom: 2px;
          }
          h6 {
            font-size: 20px;
            color: #313a58;
            font-family: $sourceSasnsProSemiBold;
          }
        }
      }
    }
  }
  &.additional-workers-overlay {
    z-index: 992;
    .no-results {
      img {
        height: 100px;
      }
      b {
        font-size: 20px;
      }
    }
    ul {
      width: 100%;
      max-height: 155px;
      overflow: auto;
      li {
        width: 100%;
        align-items: center;
        padding: 10px;
        background: #fcfcfc;
        border: 1px solid #f3f4f6;
        border-radius: 4px;
        cursor: pointer;
        h6 {
          color: #42424e;
          font-size: 18px;
          font-family: "SourceSansPro SemiBold", sans-serif;
          padding-right: 10px;
        }
        button.remove {
          margin-left: auto;
          background: transparent;
          border: none;
          cursor: pointer;
        }
        &.active,
        &:hover {
          background: #e8f1f7;
          border: 1px solid #c2d0e0;
        }
      }
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #e2eaef;
    margin: -20px;
    margin-top: 20px;
    padding: 20px;
    button.btn-primary {
      width: 100px;
      margin-left: 10px;
    }
  }
  &.show {
    opacity: 1;
    pointer-events: all;
  }
}

.MuiTabs-flexContainer {
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
  .MuiButtonBase-root {
    text-transform: initial !important;
    font-size: 14px !important;
    color: #aeb7c9 !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    &.Mui-selected {
      color: #313a58 !important;
    }
  }
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #fe5000 !important;
}

.MuiButtonBase-root {
  font-size: 14px !important;
  font-family: $sourceSasnsProRegular !important;
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
    width: 20px;
    object-fit: scale-down;
    flex-shrink: 0;
  }
  &.last {
    border-top: 1px solid #e6eef4;
  }
}

.validate {
  border: 1px solid $redColor !important;
}

.error-text {
  color: $redColor;
  font-size: 11px;
  font-family: $sourceSasnsProRegular;
  max-width: 100%;
}

.wrapper {
  width: calc(100% - 30px);
  max-width: 2100px;
  margin: 0 auto;
}

.no-results {
  padding: 40px 0;
  img {
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
    max-width: 300px;
  }
  b {
    font-size: $largeFontSize;
    display: block;
    text-align: center;
    margin-bottom: 10px;
    font-family: $sourceSasnsProSemiBold;
    color: #313a58;
  }
}

hr {
  border: none;
  margin: 5px 0;
  display: block;
  height: 1px;
  background-color: #e6eef4;
}

.btn-primary {
  background-color: $primaryOrangeColor;
  border-radius: 4px;
  font-size: 15px;
  text-align: center;
  height: 40px;
  cursor: pointer;
  border: none;
  min-width: 40px;
  padding: 0 10px;
  color: #fff;
  &:hover {
    background-color: #f64e00;
  }
}
.btn-secondary {
  background: #e8f0f7;
  border-radius: 4px;
  font-size: 15px;
  text-align: center;
  height: 40px;
  cursor: pointer;
  border: none;
  min-width: 40px;
  padding: 0 10px;
  color: #313a58;
  &:hover {
    background-color: #dbe2e9;
  }
}
.btn-add {
  background-color: #3bc870;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  border: none;
  min-width: 40px;
  padding: 0 10px;
  padding-left: 32px;
  height: 40px;
  font-size: 15px;
  background-image: url("../images/app/icons/add.svg");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: 10px center;
  &:hover {
    background-color: #38c06c;
  }
}

.loader {
  padding: 40px 0;
  img {
    width: 50px;
    display: block;
    margin: 0 auto;
  }
  &.no-padding {
    padding: 0;
  }
}

.loading {
  position: relative;
  overflow: hidden;
  pointer-events: none;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60px;
    background-image: url("../images/app/loader.svg");
    background-color: rgba(255, 255, 255, 0.95);
  }
}

.success-message,
.error-message {
  width: 420px;
  min-height: 40px;
  padding: 10px;
  position: fixed;
  bottom: 15px;
  left: 15px;
  border-radius: 6px;
  font-size: $smallFontSize;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  transition: 0.2s;
  z-index: 999;
  img {
    margin-right: 5px;
  }
  &.show {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
  }
}

.success-message {
  background-color: $darkGreenColor;
}

.error-message {
  background-color: $redColor;
}

.disabled,
[disabled] {
  opacity: 0.3 !important;
  pointer-events: none !important;
}

.row {
  display: flex;
  margin-bottom: 10px;
  .col {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    flex: 1;
    &.full {
      flex: 1;
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}

input:not(.search-by-name),
select,
textarea {
  border: 1px solid #dde6ed;
  border-radius: 4px;
  height: 40px;
  padding: 0 10px;
  font-size: 14px;
  color: #313a58;
  font-family: $sourceSasnsProSemiBold;
  &::placeholder {
    color: #d3d7df;
  }
}

textarea {
  height: 10vh;
  padding: 10px;
  resize: none;
}

section#splash {
  background-color: $primaryOrangeColor;
  box-shadow: 0px -8px 31px rgba(92, 107, 158, 0.25);
  .wrapper {
    padding: 40px 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 350px;
    }
  }
}

#schedule {
  display: flex;
  flex-direction: column;
  .weekly-tasks-list {
    flex: 1;
  }
}

#users {
  .tabs-wrapper {
    padding: 0 15px;
    padding-top: 20px;
    .css-1ujnqem-MuiTabs-root {
      .MuiButtonBase-root {
        font-size: 18px !important;
      }
    }
  }
}

#track {
  position: relative;
  z-index: 8;
  .track-filter {
    z-index: 9999;
  }
  .content-holder {
    height: calc(100% - 112px);
    position: absolute;
    width: 100%;
    left: 0;
    top: 112px;
    display: flex;
    aside {
      height: 100%;
      background-color: #fff;
      width: 390px;
      border-right: 1px solid #e2eaef;
      padding: 20px;
      transition: 0.2s;
      overflow: hidden;
      .head {
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        height: 60px;
        button {
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        b {
          color: #313a58;
          font-size: 18px;
          font-family: $sourceSasnsProSemiBold;
        }
      }
      ul {
        height: calc(100% - 60px);
        overflow: auto;
        li {
          background-color: #eaf6fd;
          border-radius: 4px;
          padding: 10px;
          margin-bottom: 10px;
          cursor: pointer;
          font-size: 20px;
          color: #313a58;
          font-family: $sourceSasnsProSemiBold;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
          &:last-of-type {
            margin-bottom: 0;
          }
          &:not(.active):hover {
            background-color: #d1e0e9;
          }
          &.active {
            position: relative;
            background-color: #313a58;
            color: #fff;
            img {
              filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(196deg)
                brightness(500%) contrast(100%);
            }
            &::after {
              content: "";
              width: 11px;
              height: 19px;
              background-image: url("../images/track/icons/right-arrow.svg");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
      .no-results {
        img {
          height: 100px;
        }
        b {
          font-size: 20px;
        }
      }
    }
    main {
      height: 100%;
      width: calc(100% - 390px);
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      .leaflet-right {
        display: none;
      }
    }
    &.wide-map {
      aside {
        // width: 0;
        // padding: 0;
        margin-left: -390px;
      }
      main {
        width: 100%;
      }
    }
  }
}

section:not(#splash):not(#login):not(#statistics) {
  min-height: calc(100vh - 74px);
  background: $hoverColor;
  &#user {
    background-color: #fff;
  }
}

.screen-top-bar {
  border-bottom: 1px solid #e2eaef;
  background-color: #fff;
  .wrapper {
    padding: 15px 0;
    justify-content: space-between;
    button.back {
      background-image: url("../images/users/icons/left-arrow.svg");
      background-size: auto 17px;
      background-repeat: no-repeat;
      background-position: center;
      width: 30px;
      height: 30px;
      display: block;
      cursor: pointer;
      border: none;
      background-color: initial;
      margin: auto 0;
      margin-right: 5px;
      &:hover {
        background-color: $hoverColor;
      }
    }
    h1,
    h2,
    ul.tabs-big li {
      font-weight: normal;
      font-family: $sourceSasnsProRegular;
      color: $darkColor;
      font-size: 28px;
    }
    ul.tabs-big {
      display: flex;
      margin: -15px;
      li {
        margin-right: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        cursor: pointer;
        color: #a9a9ab;
        position: relative;
        &:last-of-type {
          margin-right: 0;
        }
        &::after {
          content: "";
          width: 100%;
          height: 0;
          background-color: $primaryOrangeColor;
          position: absolute;
          bottom: 0;
          left: 0;
          opacity: 0;
          transition: 0.2s;
        }
        &.active {
          color: $darkColor;
          pointer-events: none;
          &::after {
            opacity: 1;
            height: 4px;
          }
        }
      }
    }
    button.btn-add {
      flex-shrink: 0;
    }
  }
}

header {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e2eaef;
  position: relative;
  z-index: 9;
  .wrapper.row {
    min-height: 62px;
    padding: 5px 0;
    align-items: center;
    .col {
      &.left {
        p {
          font-family: $sourceSasnsProRegular;
          font-size: 15px;
          color: #576a81;
        }
      }
      &.mid {
        align-items: center;
        ul.nav {
          li {
            margin: 0 10px;
            a {
              padding: 5px 10px;
              border-radius: 6px;
              min-height: 50px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              text-align: center;
              color: #aeb7c9;
              font-size: 15px;
              img {
                height: 28px;
                width: 28px;
                object-fit: contain;
                object-position: center;
                margin-bottom: 5px;
              }
              &.active {
                background-color: $primaryOrangeColor;
                color: #fff;
                img {
                  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(196deg)
                    brightness(500%) contrast(100%);
                }
              }
              &.none {
                pointer-events: none;
              }
            }
          }
        }
      }
      &.right {
        align-items: flex-end;
        .row {
          align-items: center;
          .notifications {
            position: relative;
            audio {
              opacity: 0;
              pointer-events: none;
              position: absolute;
              left: -9999px;
              top: -9999px;
            }
            .bell {
              width: 20px;
              height: 20px;
              position: relative;
              cursor: pointer;
              span {
                width: 11px;
                height: 11px;
                border-radius: 50%;
                border: 2px solid #fff;
                background-color: #ffc700;
                position: absolute;
                top: -1px;
                right: -3px;
                display: block;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
              }
            }
            .list {
              position: absolute;
              right: 0;
              top: calc(100% + 10px);
              background: #ffffff;
              box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.15);
              border-radius: 4px;
              min-width: 300px;
              h3 {
                padding: 15px 10px;
                border: 1px solid #e2eaef;
                font-size: 16px;
                font-weight: normal;
                font-family: $sourceSasnsProSemiBold;
                color: $darkColor;
              }
              .no-results {
                img {
                  width: 100px;
                  margin-bottom: 10px;
                }
                b {
                  font-size: 18px;
                }
              }
              ul {
                height: 250px;
                overflow: auto;
                li {
                  padding: 15px 10px;
                  border-bottom: 1px solid #e2eaef;
                  cursor: pointer;
                  align-items: center;
                  margin-bottom: 0;
                  .icon {
                    width: 40px;
                    height: 40px;
                    flex-shrink: 0;
                    margin-right: 10px;
                    background-image: linear-gradient(
                      to right,
                      #4cb4fd,
                      #0ae9fe
                    );
                    border-radius: 3px;
                    padding: 5px;
                    font-size: 30px;
                    color: #fff;
                    font-family: $sourceSasnsProBold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                      max-width: 100%;
                      max-height: 100%;
                    }
                  }
                  .right {
                    align-items: flex-start;
                    h6 {
                      font-family: $sourceSasnsProRegular;
                      font-weight: normal;
                      color: $darkColor;
                      font-size: 15px;
                    }
                    span {
                      color: #aeb7c9;
                      font-size: 13px;
                      font-family: $sourceSasnsProRegular;
                    }
                  }
                  &.unread {
                    .right {
                      h6 {
                        font-family: $sourceSasnsProSemiBold;
                        font-weight: 600;
                      }
                      span {
                        font-family: $sourceSasnsProSemiBold;
                      }
                    }
                  }
                  &.removed {
                    cursor: default;
                  }
                  &:hover {
                    background-color: rgb(252, 252, 252);
                  }
                  &:last-of-type {
                    border: none;
                  }
                }
              }
            }
            .new-notification {
              position: fixed;
              right: 15px;
              bottom: 15px;
              background: #ffdc7e;
              border: 1px solid #ffdc7e;
              border-radius: 4px;
              padding: 15px;
              max-width: 350px;
              cursor: pointer;
              z-index: 9;
              b {
                color: #2c2c2c;
                font-size: 18px;
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                img {
                  margin-right: 5px;
                  width: 25px;
                }
              }
              p {
                color: #535353;
                font-size: 15px;
                line-height: 1.2;
              }
              span.close {
                position: absolute;
                top: 10px;
                right: 10px;
                padding: 5px;
                width: 25px;
                height: 25px;
                img {
                  max-width: 100%;
                  max-height: 100%;
                  display: block;
                  pointer-events: none;
                }
                &:hover {
                  background-color: #d8bc70;
                }
              }
              &:hover {
                border-color: #d2b156;
              }
            }
          }
          a.user,
          span.logout {
            margin-left: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.schedule-filter,
.users-filter,
.track-filter {
  position: relative;
  z-index: 2;
  background-color: #fff;
  .top {
    width: 100%;
    justify-content: space-between;
    height: 45px;
    border-bottom: 1px solid #e2eaef;
    input.search-by-name {
      border: none;
      padding: 0 15px;
      border-right: 1px solid #e2eaef;
      color: $darkColor;
      font-size: 15px;
      background-image: url("../images/schedule/icons/search.svg");
      background-size: 14px;
      background-position: 10px center;
      background-repeat: no-repeat;
      padding-left: 30px;
      color: #42424e;
      font-family: $sourceSasnsProRegular;
      &::placeholder {
        color: #aeb7c9;
      }
    }
    .list-filter,
    .date-filter {
      position: relative;
      .label {
        background-image: url("../images/schedule/icons/bottom-pointer.svg");
        background-position: calc(100% - 10px) center;
        background-repeat: no-repeat;
        background-size: 8px 4px;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 15px;
        padding-right: 28px;
        border-right: 1px solid #e2eaef;
        color: #aeb7c9;
        font-family: $sourceSasnsProRegular;
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        &.with-items {
          color: $darkColor;
        }
      }
      .dropdown {
        background-color: #ffffff;
        border: 1px solid #e7e7e7;
        box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        min-width: 160px;
        position: absolute;
        right: 0;
        top: calc(100% + 5px);
        padding-top: 10px;
        ul {
          overflow: auto;
          height: 150px;
          width: 100%;
          li {
            padding: 0 10px;
            width: 100%;
            .MuiFormControlLabel-root {
              margin-right: 0;
              width: calc(100% + 21px);
              .MuiTypography-root {
                font-size: 15px;
                color: $darkColor;
                font-family: $sourceSasnsProRegular;
                line-height: 1;
              }
            }
          }
        }
        .footer {
          padding: 10px;
          justify-content: space-between;
          button.clear {
            // width: 35px;
            height: 35px;
            background-image: url("../images/schedule/icons/clear.svg");
            background-size: 26px;
            background-repeat: no-repeat;
            background-position: 5px center;
            cursor: pointer;
            background-color: #fff;
            border: none;
            border-radius: 2px;
            flex-shrink: 0;
            padding-left: 35px;
            padding-right: 10px;
            font-size: 13px;
            color: $darkColor;
            font-family: $sourceSasnsProRegular;
            text-align: left;
            min-width: 185px;
            &:hover {
              background-color: rgb(250, 250, 250);
            }
          }
          .btn-primary {
            height: 35px;
          }
        }
      }
    }
    .date-filter {
      .label {
        background-image: url("../images/schedule/icons/calendar-2.svg");
        background-size: 17px 17px;
        width: 140px;
      }
      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        &[type="date"]::-webkit-calendar-picker-indicator {
          background: transparent;
          bottom: 0;
          color: transparent;
          cursor: pointer;
          height: auto;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: auto;
        }
      }
    }
    button.clear-all {
      width: 50px;
      height: 100%;
      background-image: url("../images/schedule/icons/clear.svg");
      background-size: 26px;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      background-color: #fff;
      border: none;
      border-radius: 2px;
      flex-shrink: 0;
      margin-right: 5px;
    }
    .sort {
      cursor: pointer;
      background-color: #fff;
      border: none;
      border-radius: 2px;
      flex-shrink: 0;
      margin-left: 5px;
      border-left: 1px solid #e2eaef;
      align-items: center;
      padding: 0 15px;
      font-size: 15px;
      font-family: $sourceSasnsProRegular;
      color: #aeb7c9;
      img {
        flex-shrink: 0;
        margin-right: 5px;
      }
    }
  }
}

.periods {
  background-color: #eaf6fd;
  border: 1px solid #d3e3ed;
  .week {
    height: 70px;
    .select-month {
      width: 240px;
      flex-shrink: 0;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      margin-bottom: 0;
      border-right: 1px solid #d3e3ed;
      padding-right: 15px;
      button {
        width: 20px;
        height: 20px;
        border-radius: 3px;
        border: none;
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 15px;
        background-color: transparent;
        cursor: pointer;
        &.prev {
          background-image: url("../images/schedule/icons/prev.svg");
        }
        &.next {
          background-image: url("../images/schedule/icons/next.svg");
        }
        &:hover {
          background-color: #dcebf4;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        b {
          display: block;
          text-align: center;
          color: #606a82;
          font-family: $sourceSasnsProSemiBold;
          font-size: 24px;
        }
        span {
          display: block;
          text-align: center;
          color: #606a82;
          font-family: $sourceSasnsProSemiBold;
          font-size: 16px;
        }
      }
    }
    .select-week {
      flex: 1;
      justify-content: space-between;
      margin-bottom: 0;
      align-items: center;
      height: 100%;
      padding: 0 15px;
      li {
        flex: 1;
        height: 38px;
        border-radius: 4px;
        margin: 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #606a82;
        font-size: 15px;
        font-family: $sourceSasnsProSemiBold;
        &:not(.active):hover {
          background-color: #f9fdff;
          cursor: pointer;
        }
        &.active {
          background-color: $primaryOrangeColor;
          color: #fff;
          cursor: default;
        }
      }
    }
    .change-filter {
      color: #aeb7c9;
      font-size: 15px;
      height: 100%;
      align-items: center;
      padding: 0 35px;
      margin-right: -15px;
      background-color: transparent;
      border: none;
      border-left: 1px solid #d3e3ed;
      cursor: pointer;
    }
  }
  .day {
    height: 125px;
    .indicator {
      width: 195px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-right: 1px solid #d3e3ed;
      flex-shrink: 0;
      b,
      span {
        display: block;
        text-align: center;
        color: #606a82;
        font-family: $sourceSasnsProSemiBold;
        font-size: 24px;
      }
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      ul.months,
      ul.days {
        flex: 1;
        display: flex;
        align-items: center;
        li {
          flex: 1;
          height: 38px;
          border-radius: 4px;
          margin: 0 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: #606a82;
          font-size: 15px;
          font-family: $sourceSasnsProSemiBold;
          flex-direction: column;
          &:not(.active):hover {
            background-color: #f9fdff;
            cursor: pointer;
          }
          &.active {
            background-color: $primaryOrangeColor;
            color: #fff;
            cursor: default;
          }
          span {
            font-size: 13px;
          }
        }
      }

      .top {
        height: 70px;
        .year {
          width: 120px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #d3e3ed;
          >span{
            text-align: center;
            color: #606a82;
            font-family: $sourceSasnsProSemiBold;
            font-size: 18px;
            padding: 0 10px;
          }
          button {
            width: 20px;
            height: 20px;
            border-radius: 3px;
            border: none;
            background-position: center;
            background-repeat: no-repeat;
            background-size: auto 15px;
            background-color: transparent;
            cursor: pointer;
            &.prev {
              background-image: url("../images/schedule/icons/prev.svg");
            }
            &.next {
              background-image: url("../images/schedule/icons/next.svg");
            }
            &:hover {
              background-color: #dcebf4;
            }
          }
        }
        .change-filter {
          color: #aeb7c9;
          font-size: 15px;
          height: 100%;
          align-items: center;
          padding: 0 35px;
          margin-right: -15px;
          background-color: transparent;
          border: none;
          border-left: 1px solid #d3e3ed;
          cursor: pointer;
        }
      }
      .bottom.days {
        flex: 1;
        width: 100%;
        border-top: 1px solid #d3e3ed;
      }
    }
  }
}

ul.weekly-tasks-list {
  width: 100%;
  display: flex;
  > li {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 250px;
    .worker {
      height: 60px;
      flex-shrink: 0;
      text-align: center;
      color: #606a82;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $rubikSemiBold;
      width: 100%;
      border-bottom: 2px solid #e2eaef;
      background-color: $hoverColor;
      position: sticky;
      top: 0;
      z-index: 3;
    }
    ul.tasks {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 10px;
      border-right: 2px solid #e2eaef;
      background-color: $hoverColor;
      li.day {
        height: 100%;
        display: flex;
        flex-direction: column;
        h3.head {
          position: relative;
          color: #fe5000;
          font-size: 20px;
          font-family: $rubikSemiBold;
          width: fit-content;
          margin: 0 auto;
          text-transform: uppercase;
          margin-bottom: 10px;
          margin-top: 30px;
          span {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            font-size: 14px;
            color: #fff;
            background: #313a58;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: calc(100% - 1px);
            top: -9px;
          }
        }
        > ul {
          > li {
            padding: 10px;
            border-radius: 4px;
            margin-bottom: 10px;
            cursor: pointer;
            position: relative;
            .top {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;
              time {
                border-radius: 4px;
                display: flex;
                align-items: center;
                color: #fff;
                padding: 7px;
                font-size: 16px;
                width: fit-content;
                font-family: $rubikSemiBold;
                margin-left: -10px;
                margin-top: -10px;
                img {
                  margin-right: 5px;
                  flex-shrink: 0;
                }
              }
              span {
                border-radius: 4px;
                width: 23px;
                height: 23px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: -8px;
                margin-right: -3px;
                img {
                  width: calc(100% - 7px);
                  object-fit: contain;
                }
              }
            }
            b {
              color: #313a58;
              font-size: 14px;
              font-family: $rubikSemiBold;
              display: block;
              line-height: 1.2;
              position: relative;
              margin-bottom: 5px;
              width: fit-content;
              padding-right: 35px;
              button.comments {
                width: 27px;
                height: 27px;
                background: url("../images/schedule/icons/comments.svg");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                color: #fff;
                font-family: $rubikSemiBold;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 5px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                border: none;
                cursor: pointer;
              }
            }
            ul.files {
              display: flex;
              align-items: center;
              li {
                margin-right: 15px;
                button {
                  display: flex;
                  align-items: center;
                  color: #313a58;
                  font-size: 14px;
                  font-family: $rubikSemiBold;
                  background: initial;
                  border: none;
                  cursor: pointer;
                  img {
                    margin-right: 5px;
                  }
                }
                &:last-of-type {
                  margin-right: 0;
                }
              }
            }
            a {
              display: flex;
              align-items: center;
              width: fit-content;
              margin-top: 25px;
              img {
                flex-shrink: 0;
                margin-right: 5px;
              }
            }
            &:last-of-type {
              margin-bottom: 0;
            }
            &.is-complete {
              cursor: default;
              a {
                margin-right: 30px;
              }
              &::before {
                content: "";
                width: 20px;
                height: 20px;
                background-image: url("../images/schedule/icons/check.svg");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                right: 10px;
                bottom: 10px;
              }
            }
          }
        }
        p.no-tasks {
          color: #aeb7c9;
          font-size: 12px;
          font-family: $sourceSasnsProItalic;
          font-style: italic;
          text-align: center;
        }
      }
    }
    &:last-of-type {
      .tasks {
        border: none;
      }
    }
    &:nth-child(2n) {
      .tasks {
        background-color: #fff;
      }
    }
  }
}

.weekly-tasks-list-holder {
  height: calc(100vh - 262px);
  overflow: auto;
  ul.weekly-tasks-list {
    min-height: 100%;
    > li {
      ul.tasks {
        li.day {
          height: initial;
        }
      }
    }
  }
}

ul.daily-tasks-list {
  background-color: $hoverColor;
  padding: 40px;
  > li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    > time {
      color: #606a82;
      font-size: 16px;
      font-family: $sourceSasnsProSemiBold;
      position: relative;
      padding-right: 18px;
      margin-right: 15px;
      flex-shrink: 0;
      display: block;
      width: 57px;
      &::after {
        content: "";
        background: #606a82;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .task {
      border-radius: 4px;
      padding: 15px;
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      button.menu,
      .download-protocol {
        position: absolute;
        right: 15px;
        top: 15px;
        background: #ffffff;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
      }
      button.menu {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        background-image: url("../images/schedule/icons/options.svg");
        background-size: 22px auto;
        background-repeat: no-repeat;
        background-position: center;
      }
      .download-protocol {
        min-width: initial;
        padding: 3px;
        img {
          max-width: 100%;
          max-height: 100%;
          margin-left: -6px;
        }
      }
      .top {
        display: flex;
        width: 100%;
        .left {
          display: flex;
          flex-direction: column;
          width: fit-content;
          flex-shrink: 0;
          time {
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            padding: 5px 7px;
            font-size: 13px;
            width: fit-content;
            font-family: $rubikSemiBold;
            margin-left: -15px;
            margin-top: -15px;
            width: 210px;
            height: 61px;
            text-align: left;
            line-height: 1.1;
            img {
              margin-right: 5px;
              width: 25px;
              flex-shrink: 0;
            }
          }
          span {
            border-radius: 4px;
            width: 65px;
            height: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 15px;
            position: relative;
            img {
              width: calc(100% - 20px);
              object-fit: contain;
            }
            &.is-complete::before {
              content: "";
              width: 20px;
              height: 20px;
              background-image: url("../images/schedule/icons/check.svg");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              position: absolute;
              right: -2px;
              top: -2px;
            }
          }
        }
        .right {
          padding-left: 15px;
          display: flex;
          justify-content: space-between;
          flex: 1;
          .col {
            h5,
            h6 {
              color: #313a58;
              font-size: 18px;
              margin-bottom: 20px;
              height: 25px;
              font-family: $rubikSemiBold;
            }
            h5 {
              position: relative;
              padding-right: 35px;
              width: fit-content;
              button.comments {
                width: 27px;
                height: 27px;
                background: url("../images/schedule/icons/comments.svg");
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                color: #fff;
                font-family: $rubikSemiBold;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 5px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                border: none;
                cursor: pointer;
              }
            }
            h6 {
              font-size: 14px;
            }
            p {
              word-wrap: break-word;
              color: #737d9f;
              font-size: 14px;
              b {
                color: #313a58;
                font-family: $rubikSemiBold;
                margin-left: 5px;
              }
            }
            ul:not(.files) {
              li {
                color: #313a58;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                &:last-of-type {
                  margin-bottom: 0;
                }
                img {
                  width: 18px;
                  object-fit: scale-down;
                  margin-inline-end: 5px;
                }
                a.link {
                  display: flex;
                  align-items: center;
                }
              }
              &.extra-info {
                li {
                  color: #737d9f;
                  b {
                    color: #313a58;
                    font-family: $rubikSemiBold;
                    margin-left: 5px;
                  }
                }
              }
            }
            ul.files {
              display: flex;
              align-items: center;
              padding-top: 15px;
              li {
                margin-right: 15px;
                button {
                  display: flex;
                  align-items: center;
                  color: #313a58;
                  font-size: 14px;
                  font-family: $rubikSemiBold;
                  background: initial;
                  border: none;
                  cursor: pointer;
                  img {
                    margin-right: 5px;
                  }
                }
                &:last-of-type {
                  margin-right: 0;
                }
              }
            }
            &.extra-info-col {
              width: 40%;
              padding-right: 40px;
            }
            &:not(.extra-info-col) {
              flex: 0.8;
              padding-right: 60px;
            }
          }
        }
      }
      hr {
        width: 100%;
        height: 2px;
        margin: 15px 0;
      }
      p.bottom {
        color: #606a82;
        font-size: 14px;
        font-family: $sourceSasnsProRegular;
        line-height: 1.4;
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    .loader {
      margin: 0 auto;
    }
  }
}

.users {
  .wrapper {
    padding: 40px 0;
    ul.users-list {
      display: flex;
      flex-wrap: wrap;
      width: calc(100% + 15px);
      margin-right: -15px;
      li {
        background: #ffffff;
        border: 1px solid #dde6ed;
        border-radius: 4px;
        width: calc(100% / 6 - 15px);
        margin-right: 15px;
        margin-bottom: 15px;
        position: relative;
        button.menu {
          width: 20px;
          height: 20px;
          background-image: url("../images/users/icons/options.svg");
          background-size: 17px auto;
          background-repeat: no-repeat;
          background-position: center;
          background-color: initial;
          border: none;
          cursor: pointer;
          position: absolute;
          right: 15px;
          top: 15px;
          border-radius: 50%;
        }
        > a {
          padding: 30px 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .picture {
            width: 113px;
            height: 113px;
            border-radius: 50%;
            background: #89c8eb;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            font-size: 40px;
            color: #fff;
            font-family: $sourceSasnsProSemiBold;
            margin-bottom: 15px;
            &::after {
              content: "";
              width: calc(100% - 15px);
              height: calc(100% - 15px);
              border-radius: 50%;
              border: 1px solid #fff;
              position: absolute;
              left: 7px;
              top: 7px;
            }
          }
          b {
            display: block;
            text-align: center;
            color: #42424e;
            font-family: $sourceSasnsProSemiBold;
            font-size: 14px;
          }
          p {
            color: #777777;
            font-size: 12px;
            font-family: $sourceSasnsProRegular;
            text-align: center;
            margin-bottom: 10px;
          }
          .phone {
            display: flex;
            align-items: center;
            color: #0189ff;
            font-size: 14px;
            font-family: $sourceSasnsProRegular;
            img {
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}

.user-content {
  > .wrapper {
    padding: 40px 0;
    .personal-data,
    .transactions {
      background: #f5fbff;
      border: 1px solid #e1e8f0;
      border-radius: 4px;
      padding: 20px;
    }
    .personal-data {
      width: 550px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top {
        display: flex;
        align-items: center;
        .picture {
          width: 76px;
          height: 76px;
          border-radius: 50%;
          background: #89c8eb;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          font-size: 30px;
          color: #fff;
          font-family: $sourceSasnsProSemiBold;
          margin-right: 15px;
          &::after {
            content: "";
            width: calc(100% - 7px);
            height: calc(100% - 7px);
            border-radius: 50%;
            border: 1px solid #fff;
            position: absolute;
            left: 3px;
            top: 3px;
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          span {
            background: #ff8c6c;
            border-radius: 17px;
            color: #fff;
            font-size: 12px;
            font-family: $sourceSasnsProRegular;
            padding: 5px 10px;
            width: fit-content;
          }
          b {
            color: #42424e;
            font-size: 20px;
            font-family: $sourceSasnsProSemiBold;
            margin: 5px 0;
          }
          a.link {
            color: #0189ff;
            font-size: 14px;
            font-family: $sourceSasnsProRegular;
            width: fit-content;
            display: flex;
            align-items: center;
            img {
              margin-right: 5px;
            }
          }
        }
      }
      .bottom {
        background: #e9f4fd;
        border-radius: 3px;
        margin: -20px;
        margin-top: 20px;
        padding: 20px;
        .t {
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;
          b {
            color: #42424e;
            font-size: 16px;
            font-family: $sourceSasnsProSemiBold;
          }
          span {
            width: fit-content;
            color: #89a4c4;
            font-size: 14px;
            font-family: $sourceSasnsProSemiBold;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .b {
          justify-content: space-between;
          ul.row {
            flex: 1;
            justify-content: space-between;
            margin-right: 40px;
            li.col {
              flex: initial;
              padding-right: 0;
              margin-right: 10px;
              width: fit-content;
              span {
                color: #89a4c3;
                font-size: 16px;
                font-family: $sourceSasnsProRegular;
              }
              b {
                font-size: 20px;
                color: #42424e;
                font-family: $sourceSasnsProSemiBold;
              }
              &.change-salary {
                cursor: pointer;
                border-radius: 4px;
                padding: 5px;
                padding-right: 32px;
                margin: -5px;
                margin-right: 10px;
                position: relative;
                &:hover {
                  background-color: $hoverColor;
                }
                &::after {
                  content: "";
                  width: 20px;
                  height: 20px;
                  background-image: url("../images/users/icons/settings.svg");
                  background-position: center;
                  background-size: 20px auto;
                  background-repeat: no-repeat;
                  position: absolute;
                  right: 5px;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }
    }
    .transactions {
      width: calc(100% - 570px);
      margin-left: 20px;
      .top {
        align-items: center;
        justify-content: space-between;
        b {
          color: #42424e;
          font-size: 16px;
          font-family: $sourceSasnsProSemiBold;
          margin-bottom: 15px;
        }
        .legend {
          align-items: center;
          ul.points {
            align-items: center;
            margin-bottom: 0;
            li {
              align-items: center;
              margin-bottom: 0;
              color: #9cb3cf;
              font-size: 12px;
              font-family: $sourceSasnsProRegular;
              margin-left: 15px;
              span {
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 5px;
              }
              &.incomes {
                span {
                  background-color: #3ed19d;
                }
              }
              &.expenses {
                span {
                  background-color: #ff7079;
                }
              }
            }
          }
          ul.by-period {
            margin-left: 20px;
            li {
              width: 27px;
              height: 27px;
              background: #c8e5ff;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              color: #0189ff;
              font-size: 14px;
              font-family: $sourceSasnsProSemiBold;
              margin-left: 10px;
              position: relative;
              input {
                cursor: pointer;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                padding: 0;
              }
              input[type="date"]::-webkit-calendar-picker-indicator {
                background: transparent;
                bottom: 0;
                color: transparent;
                cursor: pointer;
                height: auto;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: auto;
              }
              &.active {
                background-color: #0189ff;
                color: #fff;
              }
            }
          }
        }
      }
      .bottom {
        .left {
          width: 200px;
          select {
            margin-bottom: 15px;
          }
          .row {
            align-items: center;
            img {
              width: 27px;
              object-fit: scale-down;
              flex-shrink: 0;
              margin-right: 10px;
            }
            .r {
              span {
                color: #9cb3cf;
                font-size: 16px;
                font-family: $sourceSasnsProRegular;
                margin-bottom: 2px;
              }
              b {
                color: #42424e;
                font-size: 20px;
                font-family: $sourceSasnsProSemiBold;
              }
            }
          }
        }
        .right {
          width: calc(100% - 200px);
        }
      }
    }
    .screen-top-bar {
      .wrapper {
        width: 100%;
      }
    }
    ul.daily-tasks-list {
      padding: 0;
      padding-top: 30px;
      background-color: #fff;
    }
  }
}
#finances {
  span.remove {
    display: block;
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-radius: 4px;
    background-image: url("../images/schedule/icons/remove-gray.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px auto;
    margin-left: 5px;
    &:hover {
      background-color: $hoverColor;
    }
  }
  .wrapper {
    padding: 40px 0;
    .align-right {
      text-align: right !important;
      span{
        margin-left: auto;
      }
    }
    ul {
      li.day-container {
        margin-bottom: 2px;
        border: 1px solid rgba(62, 209, 157, 0.2);
        .row {
          align-items: center;
          justify-content: space-between;
          padding: 25px 15px;
          background-color: rgba(62, 209, 157, 0.1);
          // border-bottom: 1px solid #fff;
          cursor: pointer;
          time {
            font-family: $rubikSemiBold;
            font-size: 15px;
          }
          span {
            color: #3ed19d;
            font-family: $rubikSemiBold;
            font-size: 15px;
          }
          &:hover {
            background-color: rgba(62, 209, 157, 0.2);
          }
        }
        &.red {
          border: 1px solid rgba(255, 112, 121, 0.2);
          .row {
            background-color: rgba(255, 112, 121, 0.1);
            // border-bottom: 1px solid #fff;
            span {
              color: #ff7079;
            }
            &:hover {
              background-color: rgba(255, 112, 121, 0.2);
            }
          }
        }
        table {
          width: 100%;
          thead {
            tr {
              th {
                text-align: left;
                font-family: $rubikSemiBold;
                font-weight: 500;
                font-size: $smallFontSize;
                color: $darkColor;
                padding: 10px;
                border-bottom: 1px solid #d1d1d1;
              }
            }
          }
          tbody {
            tr {
              td {
                padding: 10px;
                color: $darkColor;
                font-family: $rubikRegular;
                font-size: 15px;
                &.income {
                  color: #3ed19d;
                }
                &.expense {
                  color: #ff7079;
                }
                &:last-of-type {
                  width: 120px;
                }
                &.align-right{
                  font-family: $rubikSemiBold;
                }
              }
              &:nth-child(even) {
                background-color: #fff;
              }
            }
          }
        }
      }
    }
    .total {
      padding: 25px 15px;
      background-color: #eee;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      span,
      b {
        font-family: $rubikSemiBold;
        color: $darkColor;
        font-size: 15px;
      }
      span{
        color: #3ed19d;
        &.red {
          color: #ff7079;
        }
      }
    }
  }
}
#login {
  background-image: url("../images/login/bg.jpg");
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  form {
    background: #ffffff;
    border-radius: 10px;
    width: 390px;
    padding: 20px;
    img {
      display: block;
      margin: 0 auto;
      margin-top: 10px;
    }
    h1 {
      color: #090a0a;
      font-size: 32px;
      font-family: $rubikSemiBold;
      text-align: center;
    }
    p {
      color: #6c7072;
      text-align: center;
      font-size: 16px;
      font-family: $sourceSasnsProRegular;
      margin-bottom: 40px;
    }
    .row {
      .col {
        input {
          height: 48px;
        }
      }
    }
    button {
      width: 100%;
      margin-top: 25px;
      height: 50px;
    }
  }
}

#statistics {
  > .wrapper {
    padding: 40px 0;
    .transactions {
      width: 100%;
      background: #f5fbff;
      border: 1px solid #e1e8f0;
      border-radius: 4px;
      padding: 20px;
      .top {
        align-items: center;
        justify-content: space-between;
        b {
          color: #42424e;
          font-size: 16px;
          font-family: $sourceSasnsProSemiBold;
          margin-bottom: 15px;
        }
        .legend {
          align-items: center;
          ul.points {
            align-items: center;
            margin-bottom: 0;
            li {
              align-items: center;
              margin-bottom: 0;
              color: #9cb3cf;
              font-size: 12px;
              font-family: $sourceSasnsProRegular;
              margin-left: 15px;
              span {
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 5px;
              }
              &.incomes {
                span {
                  background-color: #3ed19d;
                }
              }
              &.expenses {
                span {
                  background-color: #ff7079;
                }
              }
              &.tasks {
                span {
                  background-color: #233d84;
                }
              }
            }
          }
          ul.by-period {
            margin-left: 20px;
            li {
              width: 27px;
              height: 27px;
              background: #c8e5ff;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              color: #0189ff;
              font-size: 14px;
              font-family: $sourceSasnsProSemiBold;
              margin-left: 10px;
              position: relative;
              input {
                cursor: pointer;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                padding: 0;
              }
              input[type="date"]::-webkit-calendar-picker-indicator {
                background: transparent;
                bottom: 0;
                color: transparent;
                cursor: pointer;
                height: auto;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                width: auto;
              }
              &.active {
                background-color: #0189ff;
                color: #fff;
              }
            }
          }
        }
      }
      .bottom {
        height: 100%;
        .left {
          width: 200px;
          select,
          .MuiAutocomplete-root {
            margin-bottom: 15px;
          }
          input::placeholder {
            color: #313a58;
          }
          .row {
            align-items: center;
            img {
              width: 27px;
              object-fit: scale-down;
              flex-shrink: 0;
              margin-right: 10px;
            }
            .r {
              span {
                color: #9cb3cf;
                font-size: 16px;
                font-family: $sourceSasnsProRegular;
                margin-bottom: 2px;
              }
              b {
                color: #42424e;
                font-size: 20px;
                font-family: $sourceSasnsProSemiBold;
              }
            }
          }
        }
        .right {
          width: calc(100% - 200px);
          height: fit-content;
          margin-top: auto;
        }
      }
    }
  }
}

#history {
  ul.daily-tasks-list {
    overflow: auto;
    max-height: calc(100vh - 268px);
  }
}
